<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="picChoose" :idx="3"></SelectImgs>
        <div style="margin-top: 40px">600*360px</div>
      </el-form-item>
      <el-form-item label="开始时间" prop="start_date">
        <el-date-picker
            v-model="info.start_date"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="end_date">
        <el-date-picker
            v-model="info.end_date"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="描述" prop="desc">
        <el-input v-model="info.desc" placeholder="请输入描述"></el-input>
      </el-form-item>
      <el-form-item label="展位号" prop="num">
        <el-input v-model="info.num" placeholder="请输入展位号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      info: {
        id: 0,
        title: '',
        img: '',
        start_date: '',
        end_date: '',
        desc: '',
        num: '',
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
    SelectImgs
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    picChoose(item) {
      this.info.img = item.pic
    },
    getInfo() {
      var that = this
      this.$api.news.newsExhibitionInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(that.info))

          this.$api.news.newsExhibitionEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
